import axios, { AxiosResponse } from 'axios';

class ReportService {
  async download(
    fromDate: string,
    fromTime: string,
    beforeDate: string,
    beforeTime: string
  ): Promise<Blob> {
    const { data }: AxiosResponse<Blob> = await axios.get(
      `${process.env.VUE_APP_BASE_URL}/statistic/transaction-statistic-for-period`,
      {
        params: {
          from_date: fromDate,
          from_time: fromTime,
          before_date: beforeDate,
          before_time: beforeTime,
        },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userTokenData'),
        },
        responseType: 'blob',
      }
    );
    return data;
  }

  async downloadPrepayment(
    id: number,
    fromDate: string,
    fromTime: string,
    beforeDate: string,
    beforeTime: string
  ): Promise<Blob> {
    const { data }: AxiosResponse<Blob> = await axios.get(
      `https://bot.tapper-dev.ru/api/report/${id}?startDate=${fromDate}%20${fromTime}:00&endDate=${beforeDate}%20${beforeTime}:59`,
      {
        // https://bot.tapper-dev.ru/api/report/23?startDate=2024-12-12%2000:00:00&endDate=2024-12-19%2000:23:59
        // https://bot.tapper-dev.ru/api/report/12?startDate=2024-11-01%2000:00:00&endDate=2025-11-01%2000:00:00

        // params: {
        //   from_date: fromDate,
        //   from_time: fromTime,
        //   before_date: beforeDate,
        //   before_time: beforeTime,
        // },
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('userTokenData'),
        },
        responseType: 'blob',
      }
    );
    return data;
  }
}

export default new ReportService();
